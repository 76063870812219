import { Dispatch } from 'react';
import { WidgetAction, WidgetState } from './Widget.store';

export enum WidgetStep {
  INDUSTRY = 0,
  APPOINTMENT = 1,
  VARIANT = 2,
  ADDON = 3,
  DATE_AND_TIME = 4,
  ADDITIONAL_INFORMATION = 5,
  SUMMARY = 6,
  PAYMENT_SUCCESS = 7,
  PAYMENT_ERROR = 8,
}

export type SumUp = (appointmentPrice?: number, variantIndex?: number, addonsPrices?: number, days?: number) => number;

export enum RecurrenceType {
  Daily = 'daily',
  Weekly = 'weekly',
  Every2Weeks = 'every-2-weeks',
  Every3Weeks = 'every-3-weeks',
  Every4Weeks = 'every-4-weeks',
}

export type AppointmentQuestion = {
  answer: string;
  question: string;
  id: number;
};

export type WidgetSteps = { type: WidgetStep }[];

export interface WidgetContextInterface {
  state: WidgetState;
  dispatch: Dispatch<WidgetAction>;
}
