import React, { useContext } from 'react';
import { Input, StepContainer } from 'UI';
import { WidgetActionType } from 'Pages/Widget/Widget.store';
import PageNoData from 'UI/PageNoData';
import useTranslation from 'shared/useTranslation';
import { WidgetContext } from 'Pages/Widget/Widget.constants';
import * as S from './styles';
import { isAdditionalInformationNotValid } from './validation';
import { ADDITIONAL_INFORMATION_LIMIT } from './constants';

const AdditionalInformationStep = () => {
  const { state, dispatch } = useContext(WidgetContext);
  const { appointmentQuestions } = state;

  const [translations, t] = useTranslation([
    'common.label.answer',
    'widget.step.additionalinformation.empty.title',
  ] as const);

  const onQuestionAnswerChange = (answer: string, questionId: number) => {
    dispatch({
      type: WidgetActionType.UPDATE_ADDITIONAL_INFO_STEP_QUESTION,
      payload: {
        answer,
        questionId,
      },
    });
  };

  if (appointmentQuestions.length === 0) {
    return <PageNoData title={translations.widgetStepAdditionalinformationEmptyTitle} />;
  }

  return (
    <StepContainer>
      <S.Container>
        <S.QuestionsContainer>
          {appointmentQuestions.map((question) => (
            <S.QuestionContainer key={question.id}>
              <S.Label htmlFor={String(question.id)}>{question.question}</S.Label>
              <Input
                id={String(question.id)}
                name={question.question}
                type="text"
                error={isAdditionalInformationNotValid(question.answer)}
                helperText={
                  isAdditionalInformationNotValid(question.answer)
                    ? t('widget.step.additionalinformation.error', { characters: ADDITIONAL_INFORMATION_LIMIT })
                    : undefined
                }
                placeholder={translations.commonLabelAnswer}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onQuestionAnswerChange(event.target.value, question.id)
                }
                value={question.answer}
              />
            </S.QuestionContainer>
          ))}
        </S.QuestionsContainer>
      </S.Container>
    </StepContainer>
  );
};

export default AdditionalInformationStep;
